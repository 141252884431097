import { Box, Button, FormControl, IconButton, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import Header from '../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const CountryDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const token = localStorage.getItem('token');

  const [values, setValues] = useState({
    name: '',
    image: '',
    status: '',
    imageURL: '',
  });
  const [image, setImage] = useState(null);

  useEffect(() => {
    getCountryDetails();
  }, []);

  const getCountryDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/country/${id}`, { headers: { token } });
    const data = await res.json();
    setValues({
      name: data.CountryName,
      image: data.CountryImage,
      status: data.CountryStatus,
      imageURL: data.CountryImageURL,
    });
  };

  const imageHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (values.name === '' || values.status === '') {
      setOpenToast(true);
      setToastmessage('Please fill all details');
      return;
    }

    if (image) {
      const formData = new FormData();
      formData.append('file', image);

      try {
        const res = await fetch(`${process.env.REACT_APP_APILINK}/country/uploadimage`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const imageData = await res.json();

        if (imageData.message) {
          setOpenToast(true);
          setToastmessage(imageData.message);
        }

        if (imageData.success) {
          values.image = imageData.image;
        }
      } catch (error) {
        console.log(error);
      }
    }

    try {
      if (values.name === '' || values.image === '' || values.status === '') {
        setOpenToast(true);
        setToastmessage('Please Fill all details');
        return;
      }
      const res = await fetch(`${process.env.REACT_APP_APILINK}/country/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify(values),
      });

      const data = await res.json();

      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            navigate('/countries');
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={values.name} subtitle="Country Details" />

        <Link to={-1}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Go Back
          </Button>
        </Link>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '10px',
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Country Name"
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            value={values.name}
            name="name"
            required
          />

          <Typography variant="h5" mt="10px" color={colors.grey[100]}>
            Country Image
          </Typography>
          <label
            htmlFor="file-input"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              padding: '15px',
              background: colors.primary[400],
              fontSize: '18px',
              fontWeight: 'bold',
              cursor: 'pointer',
              borderRadius: '10px',
              width: '400px',
              aspectRatio: '16/9',
            }}
          >
            {values.imageURL ? (
              <img
                src={values.imageURL}
                alt={values.name}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : image ? (
              <img
                src={URL.createObjectURL(image)}
                alt={values.name}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <>
                <CloudUploadIcon fontSize="large" /> Upload Image
              </>
            )}
          </label>
          <input type="file" accept="image/*" onChange={imageHandler} name="image" id="file-input" hidden />

          {/* {values.image && <input type="hidden" name="imageUpdate" value={values.image} hidden />} */}

          <Button
            onClick={() => {
              setValues({ ...values, imageURL: '' });
            }}
            sx={{
              backgroundColor: colors.grey[600],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.grey[700],
              },
            }}
          >
            Remove Image
          </Button>

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Country Status"
            onChange={(e) => setValues({ ...values, status: e.target.value })}
            value={values.status}
            name="status"
            select
          >
            <MenuItem value={'active'}>Active</MenuItem>
            <MenuItem value={'inactive'}>Inactive</MenuItem>
          </TextField>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Update
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default CountryDetails;
