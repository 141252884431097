import { Box, Button, IconButton, Snackbar, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { tokens } from '../../theme';
import { useTheme } from '@emotion/react';

const EditPassenger = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [values, setValues] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    address: '',
  });

  const token = localStorage.getItem('token');

  const getPassengerDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/passengers/${id}`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setValues(data.passenger);
    }
  };

  useEffect(() => {
    getPassengerDetails();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await fetch(`${process.env.REACT_APP_APILINK}/passengers/${id}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        token,
      },
      body: JSON.stringify(values),
    });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        setTimeout(() => {
          navigate('/allpassengers');
        }, 2000);
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="UPDATE PASSENGER" subtitle="Update Passenger Profile" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      {values && (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              onChange={handleChange}
              value={values.name}
              name="name"
              sx={{ gridColumn: 'span 2' }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Surname"
              onChange={handleChange}
              value={values.surname}
              name="surname"
              sx={{ gridColumn: 'span 2' }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="email"
              label="Email"
              onChange={handleChange}
              value={values.email}
              name="email"
              sx={{ gridColumn: 'span 4' }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Phone Number"
              onChange={handleChange}
              value={values.phone}
              name="phone"
              sx={{ gridColumn: 'span 4' }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Address"
              onChange={handleChange}
              value={values.address}
              name="address"
              sx={{ gridColumn: 'span 4' }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              UPDATE PASSENGER
            </Button>
          </Box>
        </form>
      )}

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default EditPassenger;
