import React from 'react';
import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import { tokens } from '../theme';
import { Link } from 'react-router-dom';

export default function PageNotFound() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Container maxWidth="md" m sx={{ textAlign: 'center' }}>
        <Typography variant="h1" fontSize="10rem" fontWeight="bold">
          404
        </Typography>
        <Typography variant="h6" fontSize="2.5rem">
          The page you’re looking for doesn’t exist.
        </Typography>
        <Link to="/">
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: '14px',
              fontWeight: 'bold',
              marginBlock: '20px',
              padding: '10px 20px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Back Home
          </Button>
        </Link>
      </Container>
    </Box>
  );
}
