import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

const PromotionDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const token = localStorage.getItem('token');

  const [values, setValues] = useState({
    OfferName: '',
    OfferDescription: '',
    OfferStatus: '',
    OfferImage: '',
    OfferImageUrl: '',
  });
  const [image, setImage] = useState(null);

  const getPromotionDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/offers/${id}`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setValues(data.offer);
    }
  };

  useEffect(() => {
    getPromotionDetails();
  }, []);

  const imageHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (values.OfferName === '' || values.OfferDescription === '' || values.OfferStatus === '') {
      setOpenToast(true);
      setToastmessage('Please fill all details');
      return;
    }

    if (image) {
      const formData = new FormData();
      formData.append('file', image);

      try {
        const res = await fetch(`${process.env.REACT_APP_APILINK}/offers/uploadimage`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const imageData = await res.json();

        if (imageData.message) {
          setOpenToast(true);
          setToastmessage(imageData.message);
        }

        if (imageData.success) {
          values.OfferImage = imageData.image;
        }
      } catch (error) {
        console.log(error);
      }
    }

    try {
      if (values.OfferName === '' || values.OfferDescription === '' || values.OfferStatus === '') {
        setOpenToast(true);
        setToastmessage('Please Fill all details');
        return;
      }
      const res = await fetch(`${process.env.REACT_APP_APILINK}/offers/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify(values),
      });

      const data = await res.json();

      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            navigate('/promotion');
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={values.OfferName} subtitle="Promotion Details" />

        <Link to={-1}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Go Back
          </Button>
        </Link>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '10px',
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Promotion Name"
            onChange={(e) => setValues({ ...values, OfferName: e.target.value })}
            value={values.OfferName}
            name="OfferName"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Promotion Description"
            onChange={(e) => setValues({ ...values, OfferDescription: e.target.value })}
            value={values.OfferDescription}
            name="OfferDescription"
            required
          />

          <FormControl component="fieldset">
            <Typography variant="h5" mt="10px" color={colors.grey[100]}>
              Status
            </Typography>
            <RadioGroup
              aria-label="OfferStatus"
              name="OfferStatus"
              value={values.OfferStatus}
              onChange={(e) => setValues({ ...values, OfferStatus: e.target.value })}
              row
            >
              <FormControlLabel value={1} control={<Radio color="success" />} label="Active" />
              <FormControlLabel value={0} control={<Radio color="success" />} label="Inactive" />
            </RadioGroup>
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              backgroundColor: colors.primary[400],
              padding: '15px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <Typography variant="h5" mt="10px" color={colors.grey[100]}>
                Promotion Image :
              </Typography>
              <Button
                onClick={() => {
                  setValues({ ...values, OfferImageUrl: '' });
                }}
                variant="contained"
                sx={{
                  backgroundColor: colors.primary[500],
                  color: colors.grey[100],
                  width: 'fit-content',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  padding: '8px 15px',
                  '&:hover': {
                    backgroundColor: colors.grey[700],
                  },
                }}
              >
                Remove Image
              </Button>
            </Box>
            <label
              htmlFor="file-input"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                background: colors.primary[400],
                fontSize: '18px',
                fontWeight: 'bold',
                cursor: 'pointer',
                borderRadius: '10px',
                width: '400px',
                aspectRatio: '16/9',
              }}
            >
              {values.OfferImageUrl ? (
                <img
                  src={values.OfferImageUrl}
                  alt={values.OfferName}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : image ? (
                <img
                  src={URL.createObjectURL(image)}
                  alt={values.name}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : (
                <>
                  <CloudUploadIcon fontSize="large" /> Upload Image
                </>
              )}
            </label>
            <input type="file" accept="image/*" onChange={imageHandler} name="image" id="file-input" hidden />
          </Box>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Update
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default PromotionDetails;
