import { Box, Button, CircularProgress, IconButton, Snackbar, useTheme } from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { tokens } from '../../theme';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import Header from '../../components/Header';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const AllPassengers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token');

  const getData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_APILINK}/getallpassengers`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setAllUsers(data.passengers);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  // Delete Passenger
  const handleDelete = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/passengers/${id}`, {
      headers: { token },
      method: 'DELETE',
    });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        window.location.reload();
      }
    }
  };

  const columns = [
    // { field: 'P_ID', headerName: 'P_ID' },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'surname',
      headerName: 'Surname',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
    },
    {
      field: 'View',
      renderCell: (cellValues) => {
        return (
          <Link to={`/passenger/${cellValues.row.id}`}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[500],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.blueAccent[600] },
              }}
              variant="contained"
            >
              <VisibilityIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Edit',
      renderCell: (cellValues) => {
        return (
          <Link to={`/passenger/${cellValues.row.id}/editpassenger`}>
            <Button
              sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.greenAccent[700] },
              }}
              variant="contained"
            >
              <EditIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Delete',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.redAccent[600] },
            }}
            variant="contained"
            onClick={() => handleDelete(cellValues.row.id)}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80vh' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="PASSENGERS" subtitle="Managing all Passengers" />

        <Box>
          <Link to={'/importpassengers'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                marginRight: '10px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              <DownloadIcon sx={{ mr: '10px' }} />
              Import Passenger
            </Button>
          </Link>

          <Link to={'/addpassengers'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              <PersonOutlinedIcon sx={{ mr: '10px' }} />
              Add Passenger
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="667px"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            fontSize: 14,
          },
        }}
      >
        <DataGrid
          rows={allUsers}
          columns={columns}
          getRowId={(row) => row.id}
          components={{ Toolbar: CustomToolbar }}
          autoPageSize
        />
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AllPassengers;
