import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, Snackbar, TextField, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ChangePassword({ open, handleClose, id }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const [password, setPassword] = useState('');

  const token = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await fetch(`${process.env.REACT_APP_APILINK}/changepassword/${id}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        token,
      },
      body: JSON.stringify({ password }),
    });
    const data = await res.json();

    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      setPassword('');
      handleClose();
    } else if (data.error) {
      setOpenToast(true);
      setToastmessage(data.error);
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h4" component="h2" mb="10px">
            Enter a New Password
          </Typography>

          <form onSubmit={handleSubmit}>
            <Box display="grid">
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button
                type="submit"
                sx={{
                  marginTop: '10px',
                  width: 'fit-content',
                  marginInline: 'auto',
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '10px 30px',
                  '&:hover': {
                    backgroundColor: colors.blueAccent[800],
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </div>
  );
}
