import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import { tokens } from '../../../theme';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PaidIcon from '@mui/icons-material/Paid';

const BookingInformation = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem('token');

  const [booking, setBooking] = useState(null);

  const getBookingDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/bookings/${id}`, { headers: { token } });
    const data = await res.json();
    setBooking(data.booking);
  };

  useEffect(() => {
    getBookingDetails();
  }, []);

  return (
    <>
      {booking && (
        <Box m="20px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="Booking Details" subtitle="" />

            <Box>
              <Link to={-1}>
                <Button
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    '&:hover': {
                      backgroundColor: colors.blueAccent[800],
                    },
                  }}
                >
                  Go Back
                </Button>
              </Link>
            </Box>
          </Box>

          <Box backgroundColor={colors.primary[400]} borderRadius={5} p="1.5rem">
            <Box display="grid" gap="10px">
              {/* booking Details */}
              <Box mb="2rem" display="grid" gap="10px">
                <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
                  Booking Details
                </Typography>

                <Box display="flex" gap="1rem" alignItems="center">
                  <Box>
                    <img src={booking.QRCodeUrl} width="200px" height="200px" alt="" />
                  </Box>
                  <Box flex={1} display="grid" gap="10px">
                    <Box display="flex" gap="10px">
                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <PersonIcon /> Country
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.CountryName}
                        </Typography>
                      </Box>

                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <PersonIcon /> Package
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.PackageName}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" gap="10px">
                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <CalendarTodayIcon /> Booking Start Date
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.BookingStartDate}
                        </Typography>
                      </Box>

                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <CalendarTodayIcon /> Booking Start Date
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.BookingEndDate}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                      border={1}
                      borderColor={colors.primary[300]}
                      borderRadius="8px"
                      p="10px"
                    >
                      <Typography
                        variant="h5"
                        color={colors.greenAccent[500]}
                        display="flex"
                        alignItems="center"
                        gap="5px"
                      >
                        <PaidIcon /> Payment Status
                      </Typography>
                      <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                        {booking.PaymentStatus}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Passenger Details */}
              <Box display="grid" gap="10px" mb="2rem">
                <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
                  Passenger Details
                </Typography>

                <Box display="flex" gap="10px">
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <PersonIcon /> Name
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.P_Name}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <PersonIcon /> Surname
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.P_Surname}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" gap="10px">
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <MailOutlineIcon /> Email
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.P_Email}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <LocalPhoneIcon /> Phone Number
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.P_PhoneNumber}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                  border={1}
                  borderColor={colors.primary[300]}
                  borderRadius="8px"
                  p="10px"
                >
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <PlaceIcon /> Address
                  </Typography>
                  <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                    {booking.P_Address}
                  </Typography>
                </Box>
              </Box>

              {/* guide Details */}
              <Box display="grid" gap="10px" mb="2rem">
                <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
                  Guide Details
                </Typography>

                <Box display="flex" gap="10px">
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <PersonIcon /> Guide Name
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.GuideName}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <PersonIcon /> Guide Surname
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.GuideSurname}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" gap="10px">
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <MailOutlineIcon /> Guide Email
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.GuideEmail}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <LocalPhoneIcon /> Guide Phone Number
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.GuidePhone}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" gap="10px">
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <BadgeIcon /> Guide License
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.GuideLicense}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <DriveEtaIcon /> Guide Vehicle No
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.GuideVehicleNo}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                  border={1}
                  borderColor={colors.primary[300]}
                  borderRadius="8px"
                  p="10px"
                >
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <PlaceIcon /> Guide Address
                  </Typography>
                  <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                    {booking.GuideAddress}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BookingInformation;
