import { useEffect, useLayoutEffect, useState } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PublicIcon from '@mui/icons-material/Public';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DiscountIcon from '@mui/icons-material/Discount';
import FlightIcon from '@mui/icons-material/Flight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MessageIcon from '@mui/icons-material/Message';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState();
  const [selected, setSelected] = useState('Dashboard');
  const [adminUser, setAdminUser] = useState('');
  const navigate = useNavigate();

  const getAdmin = async () => {
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin`, { headers: { token } });
    const data = await res.json();

    if (data && data.success) {
      setAdminUser(data.user);
    } else {
      navigate('/admin/login');
    }
  };

  useEffect(() => {
    // collapse in mobile view
    if (window.innerWidth < 700) {
      setIsCollapsed(true);
    }
  }, []);

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <Box
      // style={{ position: 'fixed', top: 0, bottom: 0 }}
      sx={{
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#868dfb !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: '10px 0 20px 0',
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography variant="h3" color={colors.grey[100]}>
                  Rapsody
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* {!isCollapsed && (
            <Box mb="25px">
              <Box textAlign="center">
                {adminUser && (
                  <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: '10px 0 0 0', textTransform: 'capitalize' }}
                  >
                    {adminUser}
                  </Typography>
                )}
              </Box>
            </Box>
          )} */}

          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item title="DASHBOARD" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
            <Item
              title="PASSENGERS"
              to="/allpassengers"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="COUNTRIES"
              to="/countries"
              icon={<PublicIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="EXCURSION"
              to="/excursions"
              icon={<FlightIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item title="PACKAGES" to="/package" icon={<PlaceIcon />} selected={selected} setSelected={setSelected} />

            <Item
              title="GUIDES"
              to="/guide"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="BOOKINGS"
              to="/booking"
              icon={<CalendarTodayIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="PROMOTIONS"
              to="/promotion"
              icon={<DiscountIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="FEEDBACK QUESTIONS"
              to="/feedback"
              icon={<HelpOutlineIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="USER FEEDBACKS"
              to="/userfeedback"
              icon={<FeedbackIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item title="MESSAGES" to="/message" icon={<MessageIcon />} selected={selected} setSelected={setSelected} />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
