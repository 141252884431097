import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Header from '../../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

const AdditionalBookings = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const token = localStorage.getItem('token');

  const [values, setValues] = useState({
    bookingId: '',
    paymentStatus: '',
    passengerName: '',
    passengerEmail: '',
  });

  const [excursions, setExcursions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/additionalbookings/${id}`, { headers: { token } });
      const data = await res.json();

      if (data.success) {
        setValues({
          bookingId: data.data.bookingID,
          paymentStatus: data.data.paymentStatus,
          passengerName: data.data.passengerName,
          passengerEmail: data.data.passengerEmail,
        });

        setExcursions(data.data.excursions);
      }
    };
    getData();
  }, []);

  const handlevalidation = () => {
    if (!values.paymentStatus) {
      setOpenToast(true);
      setToastmessage('Please select payment status');
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (handlevalidation()) {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/additionalbookings/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify({ paymentStatus: values.paymentStatus }),
      });
      const data = await res.json();

      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            navigate('/booking');
          }, 2000);
        }
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ADDITIONAL BOOKINGS" subtitle="" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '1.25rem',
            paddingBottom: '20px',
          }}
        >
          <Box display="grid" gap="10px">
            <Box display="flex" gap="10px">
              <Typography variant="h5" fontSize={18} color={colors.greenAccent[400]}>
                Name :
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                fontSize={18}
                color={colors.grey[100]}
                sx={{ textTransform: 'capitalize' }}
              >
                {values?.passengerName}
              </Typography>
            </Box>

            <Box display="flex" gap="10px">
              <Typography variant="h5" fontSize={18} color={colors.greenAccent[400]}>
                Email :
              </Typography>
              <Typography variant="h5" fontWeight="bold" fontSize={18} color={colors.grey[100]}>
                {values?.passengerEmail}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" fontWeight="bold" fontSize={18} color={colors.grey[100]}>
              Additional Excursions
            </Typography>

            <TableContainer
              sx={{
                margin: '10px 0 0 0',
                '& .MuiTable-root': {
                  border: 'none',
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                },
                '& .MuiTableCell-root': {
                  borderBottom: 'none',
                  fontSize: 14,
                },
                '& .MuiTableCell-root.name-column--cell': {
                  color: colors.grey[100],
                },
                '& .MuiTableHead-root': {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: 'none',
                },
                '& .MuiTableBody-root': {
                  backgroundColor: colors.primary[400],
                },
                '& .MuiTableCell-body': {
                  paddingBlock: '5px',
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Excursion Image</TableCell>
                    <TableCell>Excursion Name</TableCell>
                    <TableCell>Excursion Description</TableCell>
                    <TableCell>Excursion Location</TableCell>
                    <TableCell>Excursion price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {excursions?.map((excursion) => (
                    <TableRow key={excursion.ExcursionID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        <img
                          src={excursion.ExcursionImage}
                          style={{ width: '80px', aspectRatio: '16/9', objectFit: 'cover' }}
                        />
                      </TableCell>
                      <TableCell>{excursion.ExcursionName}</TableCell>
                      <TableCell>{excursion.ExcursionDescription}</TableCell>
                      <TableCell>{excursion.ExcursionLocation}</TableCell>
                      <TableCell>{excursion.ExcursionPrice}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Payment */}
          <FormControl component="fieldset">
            <Typography variant="h5" fontWeight="bold" fontSize={18} color={colors.grey[100]}>
              Payment Status
            </Typography>
            <RadioGroup
              name="PaymentStatus"
              value={values.paymentStatus}
              onChange={(e) => setValues({ ...values, paymentStatus: e.target.value })}
              row
            >
              <FormControlLabel value="paid" control={<Radio color="success" />} label="Paid" />
              <FormControlLabel value="unpaid" control={<Radio color="success" />} label="Not Paid" />
            </RadioGroup>
          </FormControl>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Update
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AdditionalBookings;
