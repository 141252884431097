import { useState } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import AllPassengers from './scenes/allPassengers';
import AddPassenger from './scenes/addPassenger';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import PassengerDetails from './scenes/passengerDetails';
import AddMember from './scenes/addMember';
import AdminLogin from './scenes/adminLogin';
import ImportPassenger from './scenes/importPassenger';
import TravelCountry from './scenes/allCountry';
import AddCountry from './scenes/addCountry';
import Excursion from './scenes/excursion';
import AddExcursion from './scenes/addExcursion';
import CountryDetails from './scenes/countryDetails';
import ExcursionDetails from './scenes/ExcursionDetails';
import ExcursionGallery from './scenes/excursionGallery';
import AllPackages from './scenes/packages/allPackages';
import PackageDetails from './scenes/packages/packageDetails';
import AddPackage from './scenes/packages/addPackage';
import AllGuides from './scenes/guide/allGuides';
import AddGuide from './scenes/guide/addGuide';
import GuideDetails from './scenes/guide/guideDetails';
import AllBookings from './scenes/bookings/allBookings';
import AddBooking from './scenes/bookings/addBooking';
import BookingDetails from './scenes/bookings/bookingDetails';
import BookingInformation from './scenes/bookings/bookingInformation';
import EditPassenger from './scenes/editPassenger';
import AdminAuthentication from './components/AdminAuthentication';
import AllPromotions from './scenes/promotions/allPromotions';
import AddPromotion from './scenes/promotions/addPromotion';
import PromotionDetails from './scenes/promotions/promotionDetails';
import Feedback from './scenes/feedback/Feedback';
import PageNotFound from './components/PageNotFound';
import AddFeedback from './scenes/feedback/AddFeedback';
import FeedbackDetails from './scenes/feedback/FeedbackDetails';
import UserFeedbacks from './scenes/userFeedback/UserFeedbacks';
import UserFeedbacksDetails from './scenes/userFeedback/UserFeedbacksDetails';
import Messages from './scenes/messages/Messages';
import AddMessage from './scenes/messages/AddMessage';
import MessageDetails from './scenes/messages/MessageDetails';
import AdditionalBookings from './scenes/bookings/additionalBookings';

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Routes>
            <Route
              path="/"
              element={
                <AdminAuthentication>
                  <Layout />
                </AdminAuthentication>
              }
            >
              <Route index element={<Dashboard />} />
              <Route path="/allpassengers" element={<AllPassengers />} />
              <Route path="/addpassengers" element={<AddPassenger />} />
              <Route path="/importpassengers" element={<ImportPassenger />} />
              <Route path="/passenger/:id" element={<PassengerDetails />} />
              <Route path="/passenger/:id/addmember" element={<AddMember />} />
              <Route path="/passenger/:id/editpassenger" element={<EditPassenger />} />

              <Route path="/countries" element={<TravelCountry />} />
              <Route path="/countries/addcountry" element={<AddCountry />} />
              <Route path="/countries/:id" element={<CountryDetails />} />

              <Route path="/excursions" element={<Excursion />} />
              <Route path="/excursions/addexcursion" element={<AddExcursion />} />
              <Route path="/excursions/:id" element={<ExcursionDetails />} />
              <Route path="/excursions/:id/gallery" element={<ExcursionGallery />} />

              <Route path="/package" element={<AllPackages />} />
              <Route path="/package/addpackage" element={<AddPackage />} />
              <Route path="/package/:id" element={<PackageDetails />} />

              <Route path="/guide" element={<AllGuides />} />
              <Route path="/guide/addguide" element={<AddGuide />} />
              <Route path="/guide/:id" element={<GuideDetails />} />

              <Route path="/booking" element={<AllBookings />} />
              <Route path="/booking/addBooking" element={<AddBooking />} />
              <Route path="/booking/:id" element={<BookingInformation />} />
              <Route path="/booking/:id/update" element={<BookingDetails />} />
              <Route path="/booking/:id/additional" element={<AdditionalBookings />} />

              <Route path="/promotion" element={<AllPromotions />} />
              <Route path="/promotion/addpromotion" element={<AddPromotion />} />
              <Route path="/promotion/:id" element={<PromotionDetails />} />

              <Route path="/feedback" element={<Feedback />} />
              <Route path="/feedback/addfeedback" element={<AddFeedback />} />
              <Route path="/feedback/:id" element={<FeedbackDetails />} />

              <Route path="/userfeedback" element={<UserFeedbacks />} />
              <Route path="/userfeedback/:id" element={<UserFeedbacksDetails />} />

              <Route path="/message" element={<Messages />} />
              <Route path="/message/sendmessage" element={<AddMessage />} />
              <Route path="/message/:id" element={<MessageDetails />} />
            </Route>
            <Route path="/admin/login" element={<AdminLogin />} />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

const Layout = () => {
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <>
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Outlet />
      </main>
    </>
  );
};
