import { Box, Button, IconButton, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import Header from '../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const ExcursionGallery = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const [imageFiles, setImageFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const [galleryImages, setGalleryImages] = useState([]);

  const token = localStorage.getItem('token');

  const imageHandler = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);
    const urls = files.map((file) => URL.createObjectURL(file));
    setImageUrls(urls);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (imageFiles.length > 0) {
      const formData = new FormData();
      imageFiles.forEach((file) => {
        formData.append('files', file);
      });

      try {
        const imageRes = await fetch(`${process.env.REACT_APP_APILINK}/excursionGallery/uploadgalleryimages`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const imageData = await imageRes.json();

        if (imageData.message) {
          setOpenToast(true);
          setToastmessage(imageData.message);
        }
        if (imageData.success) {
          const res = await fetch(`${process.env.REACT_APP_APILINK}/excursionGallery/addexcursionGallery`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              token,
            },
            body: JSON.stringify({ images: imageData.images, ExcursionID: id }),
          });
          const data = await res.json();

          if (data.message) {
            setOpenToast(true);
            setToastmessage(data.message);
          }
          if (data.success) {
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpenToast(true);
      setToastmessage('Please Select image(s)');
    }
  };

  const getGalleryImages = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/excursionGallery/${id}`, { headers: { token } });
    const data = await res.json();
    if (data.images) {
      setGalleryImages(data.images);
    }
  };

  useEffect(() => {
    getGalleryImages();
  }, []);

  // Delete image
  const handleDeleteImage = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/excursionGallery/${id}`, {
      headers: { token },
      method: 'DELETE',
    });
    const data = await res.json();

    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
    }
    if (data.success) {
      setGalleryImages((prevImages) => prevImages.filter((image) => image.GalleryID !== id));
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="GALLERY" subtitle="View Gallery" />
        <Link to={-1}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Go Back
          </Button>
        </Link>
      </Box>

      <Box backgroundColor={colors.primary[400]} borderRadius={3} p="1.5rem">
        <Box display="grid" gap="10px">
          <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
            Upload Images
          </Typography>

          <form onSubmit={handleFormSubmit}>
            <Box>
              <label
                htmlFor="file-input"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  flexWrap: 'wrap',
                  gap: '10px',
                  padding: '15px',
                  background: 'rgba(255,255,255,0.05)',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                {imageUrls.length > 0 ? (
                  imageUrls.map((imageUrl, index) => (
                    <img
                      key={index}
                      src={imageUrl}
                      className="addproduct-thumbnail-img"
                      alt={`Image Upload ${index}`}
                      style={{ width: '200px', objectFit: 'cover' }}
                    />
                  ))
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <CloudUploadIcon fontSize="large" /> Upload Images
                  </Box>
                )}
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={imageHandler}
                name="image"
                id="file-input"
                hidden
                multiple
              />

              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                  marginBlock: '10px',
                  width: 'fit-content',
                  '&:hover': {
                    backgroundColor: colors.blueAccent[800],
                  },
                }}
              >
                Upload Images
              </Button>
            </Box>
          </form>
        </Box>
      </Box>

      {/* Show Gallery Images */}
      <Box backgroundColor={colors.primary[400]} borderRadius={3} p="1.5rem" mt="1.5rem">
        <Box display="grid" gap="10px" mb="2rem">
          <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
            Gallery Images
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            {galleryImages &&
              galleryImages.map((image, index) => (
                <div
                  key={index}
                  style={{
                    background: 'rgba(255,255,255,0.05)',
                    textAlign: 'center',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  }}
                >
                  <img
                    src={image.imageURL}
                    style={{ width: '100%', aspectRatio: '16/9', objectFit: 'cover', display: 'block' }}
                  />
                  <Button
                    onClick={() => handleDeleteImage(image.GalleryID)}
                    sx={{
                      backgroundColor: colors.redAccent[500],
                      color: colors.grey[100],
                      fontSize: '14px',
                      fontWeight: 'bold',
                      padding: '5px 10px',
                      marginBlock: '10px',
                      width: 'fit-content',
                      '&:hover': {
                        backgroundColor: colors.redAccent[600],
                      },
                    }}
                  >
                    Remove Image
                  </Button>
                </div>
              ))}
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default ExcursionGallery;
