import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

const AddPackage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const token = localStorage.getItem('token');

  const [packageDetails, setPackageDetails] = useState({
    PackageName: '',
    PackageDescription: '',
    PackageDuration: '',
    PackageLocation: '',
    PackageTypeOfTravel: '',
    PackageImage: '',
    CountryID: '',
    ExcursionIDs: [],
  });
  const [excursions, setExcursions] = useState([]);
  const [country, setCountry] = useState([]);
  const [image, setImage] = useState(null);

  const getCountryNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountry(data);
  };

  useEffect(() => {
    getCountryNames();
  }, []);

  const getExcursions = async (countryID) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countryExcursions/${countryID}`, { headers: { token } });
    const data = await res.json();
    setExcursions(data.excursions);
  };

  useEffect(() => {
    if (packageDetails.CountryID) {
      getExcursions(packageDetails.CountryID);
    }
  }, [packageDetails.CountryID]);

  const handleChange = (e) => {
    setPackageDetails({ ...packageDetails, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (e) => {
    setPackageDetails((prevPackageDetails) => ({
      ...prevPackageDetails,
      [e.target.name]: e.target.value,
      ExcursionIDs: [],
    }));
  };

  const imageHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (image) {
      const formData = new FormData();
      formData.append('file', image);
      try {
        const res = await fetch(`${process.env.REACT_APP_APILINK}/packages/uploadimage`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const imageData = await res.json();

        if (imageData.message) {
          setOpenToast(true);
          setToastmessage(imageData.message);
        }

        if (imageData.success) {
          packageDetails.PackageImage = imageData.image;

          const res = await fetch(`${process.env.REACT_APP_APILINK}/packages`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              token,
            },
            body: JSON.stringify(packageDetails),
          });

          const data = await res.json();

          if (data.message) {
            setOpenToast(true);
            setToastmessage(data.message);
            if (data.success) {
              setTimeout(() => {
                navigate('/package');
              }, 2000);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box p="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Add Package" subtitle="Add a new Package" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '1rem',
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Package Name"
            value={packageDetails.PackageName}
            onChange={handleChange}
            name="PackageName"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Package Description"
            value={packageDetails.PackageDescription}
            onChange={handleChange}
            name="PackageDescription"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Package Duration"
            value={packageDetails.PackageDuration}
            onChange={handleChange}
            name="PackageDuration"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Package Location"
            value={packageDetails.PackageLocation}
            onChange={handleChange}
            name="PackageLocation"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Type of Travel"
            value={packageDetails.PackageTypeOfTravel}
            onChange={handleChange}
            name="PackageTypeOfTravel"
            required
          />

          <TextField
            select
            variant="filled"
            label="Country"
            value={packageDetails.CountryID}
            onChange={handleCountryChange}
            name="CountryID"
            required
          >
            {country.map((country) => (
              <MenuItem key={country.CountryID} value={country.CountryID}>
                {country.CountryName}
              </MenuItem>
            ))}
          </TextField>

          <FormControl>
            <InputLabel id="demo-multiple-checkbox-label">Excursions</InputLabel>
            <Select
              label="Excursions"
              labelId="demo-multiple-checkbox-label"
              variant="filled"
              value={packageDetails.ExcursionIDs}
              onChange={handleChange}
              name="ExcursionIDs"
              multiple
              required
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const excursion = excursions.find((exc) => exc.ExcursionID === value);
                    return <Chip key={value} label={excursion ? excursion.ExcursionName : value} />;
                  })}
                </Box>
              )}
            >
              {excursions &&
                excursions.map((excursion) => (
                  <MenuItem key={excursion.ExcursionID} value={excursion.ExcursionID}>
                    <ListItemIcon>
                      <Checkbox checked={packageDetails.ExcursionIDs.includes(excursion.ExcursionID)}></Checkbox>
                    </ListItemIcon>
                    <ListItemText primary={excursion.ExcursionName}></ListItemText>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h5" mt="10px" color={colors.grey[100]}>
              Package Image
            </Typography>
            <label
              htmlFor="file-input"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                padding: '15px',
                background: colors.primary[400],
                fontSize: '18px',
                fontWeight: 'bold',
                cursor: 'pointer',
                borderRadius: '10px',
                width: '400px',
                aspectRatio: '16/9',
              }}
            >
              {image ? (
                <img
                  src={URL.createObjectURL(image)}
                  alt="Image Upload"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : (
                <>
                  <CloudUploadIcon fontSize="large" /> Upload Image
                </>
              )}
            </label>
            <input type="file" accept="image/*" onChange={imageHandler} name="image" id="file-input" hidden />
          </Box>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AddPackage;
