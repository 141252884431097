import { Box, Button, IconButton, Snackbar } from '@mui/material';
import Header from '../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const ImportPassenger = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const [file, setFile] = useState(null);

  const token = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setOpenToast(true);
    setToastmessage('File Uploaded');

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const res = await fetch(`${process.env.REACT_APP_APILINK}/importpassenger`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const data = await res.json();
        setOpenToast(false);
        if (data.message) {
          setOpenToast(true);
          setToastmessage(data.message);
          if (data.success) {
            setTimeout(() => {
              navigate(`/allpassengers`);
            }, 2000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="IMPORT PASSENGERS" subtitle="Import Passenger Details using CSV" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap="10px">
          <label
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              padding: '15px 30px',
              background: colors.primary[400],
              // width: 'fit-content',
              fontSize: '18px',
              fontWeight: 'bold',
              cursor: 'pointer',
              borderRadius: '10px',
            }}
          >
            <CloudUploadIcon /> Choose file
            <input type="file" accept="text/csv" onChange={(e) => setFile(e.target.files[0])} hidden />
          </label>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Upload File
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default ImportPassenger;
