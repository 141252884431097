import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

const BookingDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const token = localStorage.getItem('token');

  const [booking, setBooking] = useState({
    CountryID: '',
    PackageID: '',
    GuideID: null,
    P_ID: null,
    PaymentStatus: '',
    BookingStartDate: '',
    BookingEndDate: '',
  });

  const [countryNames, setCountryNames] = useState([]);
  const [packageNames, setPackageNames] = useState([]);
  const [guideNames, setGuideNames] = useState([]);
  const [passengerNames, setPassengerNames] = useState([]);

  const getBooking = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/bookings/${id}`, { headers: { token } });
    const data = await res.json();
    setBooking(data.booking);
  };

  const getCountryNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountryNames(data);
  };

  const getPackageNames = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrypackages/${id}`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setPackageNames(data.packages);
    }
  };

  const getGuideNames = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/guidenames/${id}`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setGuideNames(data.guides);
    }
  };

  const getPassengerNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/passengernames`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setPassengerNames(data.passengers);
    }
  };

  useEffect(() => {
    getBooking();
    getCountryNames();
    getPassengerNames();
  }, []);

  useEffect(() => {
    if (booking.CountryID) {
      getPackageNames(booking.CountryID);
      getGuideNames(booking.CountryID);
    }
  }, [booking.CountryID]);

  const handleChange = (e) => {
    setBooking({ ...booking, [e.target.name]: e.target.value });
  };

  const handleAutoCompleteChange = (name, value) => {
    setBooking({ ...booking, [name]: value });
  };

  const handlevalidation = () => {
    const { CountryID, PackageID, GuideID, P_ID, PaymentStatus, BookingStartDate, BookingEndDate } = booking;
    if (
      CountryID === '' ||
      PackageID === '' ||
      GuideID === null ||
      P_ID === null ||
      PaymentStatus === '' ||
      BookingStartDate === '' ||
      BookingEndDate === ''
    ) {
      setOpenToast(true);
      setToastmessage('Please fill all Details');
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (handlevalidation()) {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/bookings/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify(booking),
      });
      const data = await res.json();

      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            navigate('/booking');
          }, 2000);
        }
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="BOOKING DETAILS" subtitle="" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '1.25rem',
            paddingBottom: '20px',
          }}
        >
          {/* Country */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Country"
            onChange={(e) => {
              setBooking({ ...booking, [e.target.name]: e.target.value, PackageID: '', GuideID: '' });
            }}
            value={booking.CountryID}
            name="CountryID"
            select
            // required
          >
            {countryNames &&
              countryNames.map((country) => (
                <MenuItem key={country.CountryID} value={country.CountryID}>
                  {country.CountryName}
                </MenuItem>
              ))}
          </TextField>

          {/* Package */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Package Name"
            onChange={handleChange}
            value={booking.PackageID}
            name="PackageID"
            select
            // required
          >
            {packageNames &&
              packageNames.map((pkg) => (
                <MenuItem key={pkg.PackageID} value={pkg.PackageID}>
                  {pkg.PackageName}
                </MenuItem>
              ))}
          </TextField>

          {/* Guide */}
          {guideNames && (
            <Autocomplete
              options={guideNames}
              value={guideNames.find((guide) => guide.id === booking.GuideID) || null}
              getOptionLabel={(option) => `${option.name} ${option.surname} (${option.email})`}
              onChange={(e, value) => {
                handleAutoCompleteChange('GuideID', value ? value.id : null);
              }}
              renderInput={(params) => <TextField {...params} variant="filled" label="Guide" />}
            />
          )}

          {/* Passenger */}
          {passengerNames && (
            <Autocomplete
              options={passengerNames}
              value={passengerNames.find((passenger) => passenger.id === booking.P_ID) || null}
              getOptionLabel={(option) => `${option.name} ${option.surname} (${option.email})`}
              onChange={(e, value) => {
                setBooking({ ...booking, P_ID: value ? value.id : null });
              }}
              renderInput={(params) => <TextField {...params} variant="filled" label="Passenger" />}
            />
          )}

          {/* Multiple Passengers */}
          {/* {passengerNames && (
            <Autocomplete
              options={passengerNames}
              multiple
              value={passengerNames.filter((passenger) => booking.P_IDs.includes(passenger.P_ID))}
              getOptionLabel={(option) => `${option.P_Name} ${option.P_Surname} (${option.P_Email})`}
              onChange={(e, value) => {
                setBooking({ ...booking, P_IDs: value.map((passenger) => passenger.P_ID) });
              }}
              renderInput={(params) => <TextField {...params} variant="filled" label="Passengers" />}
            />
          )} */}

          {/* Booking Date */}
          <Box display="flex" gap="10px">
            <TextField
              type="date"
              fullWidth
              variant="filled"
              label="Booking Start Date"
              onChange={handleChange}
              value={booking.BookingStartDate}
              name="BookingStartDate"
              InputLabelProps={{ shrink: true, style: { color: colors.grey[300] } }}
              // required
            />
            <TextField
              type="date"
              fullWidth
              variant="filled"
              label="Booking End Date"
              onChange={handleChange}
              value={booking.BookingEndDate}
              name="BookingEndDate"
              InputLabelProps={{ shrink: true, style: { color: colors.grey[300] } }}
              // required
            />
          </Box>

          {/* Payment */}
          <FormControl component="fieldset">
            <Typography variant="h5" color={colors.grey[100]}>
              Payment Status
            </Typography>
            <RadioGroup name="PaymentStatus" value={booking.PaymentStatus} onChange={handleChange} row>
              <FormControlLabel value="paid" control={<Radio color="success" />} label="Paid" />
              <FormControlLabel value="unpaid" control={<Radio color="success" />} label="Not Paid" />
            </RadioGroup>
          </FormControl>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Update
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default BookingDetails;
